<template>
	<app v-scroll="handleScroll">

		<about-app-header :flat="isScrolledToTop"/>

		<app-content>
			<slot/>
		</app-content>

		<app-footer :items="footerItems"/>
	</app>
</template>


<script setup lang="ts">
import {nextIcon} from "@spoferan/nuxt-spoferan/icons";

const {$image, $web, $spoferanUrl} = useNuxtApp();
const route = useRoute();
const config = useRuntimeConfig();
const {t} = useI18n();

const isScrolledToTop = ref(true);
function handleScroll() {
	isScrolledToTop.value = window.scrollY < 100;
}

const icons = {
	next: nextIcon,
};

const footerItems = [
	{
		title: t('label.product'),
		items: [
			{to: $web('index'), text: t('label.general'), exact: true},
			{to: $web('spoferan.index'), text: 'SPOFERAN'},
			{to: $web('resultastic.index'), text: 'resultastic'},
			{to: $web('clubmanager.index'), text: 'Clubmanager'},
			{to: $web('businessmanager.index'), text: 'Businessmanager'},
		]
	},
	{
		title: t('label.company'),
		items: [
			{to: `${config.public.pressUrl}/about`, text: t('label.general')},
			{to: config.public.jobsUrl, text: t('label.jobs')},
			{to: config.public.pressUrl, text: t('label.press')},
			{to: $spoferanUrl('team'), text: t('label.team')},
		]
	},
	{
		title: t('label.help_and_contact'),
		items: [
			{to: $spoferanUrl('contact'), text: t('label.contact')},
			{to: $spoferanUrl('support'), text: t('label.support')},
			{to: config.public.guideUrl, text: t('label.guide')},
			{to: `${config.public.guideUrl}/spoferan/faq`, text: t('label.faq')}
		]
	},
	{
		title: t('label.policies'),
		items: [
			{to: $spoferanUrl('gtc'), text: t('label.gtc')},
			{to: $spoferanUrl('privacy-policy'), text: t('label.privacy')},
			{to: $spoferanUrl('imprint'), text: t('label.imprint')},
			{to: config.public.spoferanUrl, text: t('action.to_spoferan')},
		]
	},
];

</script>