<template>
	<header class="app-header fixed flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full bg-white text-sm dark:bg-neutral-800" :class="{'shadow': !props.flat}">
		<container>
			<div class="relative md:flex md:items-center md:justify-between">
				<div class="h-full flex items-center justify-between py-3">
					<div class="flex items-center h-full">
						<transition-group name="slide-list-top-transition">
							<template v-for="logo in logos" :key="logo.value">
								<nuxt-link v-show="visibleLogoValue === logo.value" to="/" class="flex items-center h-full">
									<cdn-image :src="logo.src" :height="logo.height"
											   :class="{'flex dark:hidden': !!logo.white}"
											   :ratio="logo.ratio" :alt="logo.name"/>
									<cdn-image v-if="logo.white" :src="logo.white.src" :height="logo.white.height"
											   class="hidden dark:flex"
											   :ratio="logo.white.ratio" :alt="logo.name"/>
								</nuxt-link>
							</template>
						</transition-group>
					</div>
					<div class="md:hidden">
						<btn @click="showNavItems = !showNavItems" :aria-label="t('label.menu')">
							<svg v-if="!showNavItems" class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
								 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
								<line x1="3" x2="21" y1="6" y2="6"/>
								<line x1="3" x2="21" y1="12" y2="12"/>
								<line x1="3" x2="21" y1="18" y2="18"/>
							</svg>
							<svg v-else class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
								 stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
								<path d="M18 6 6 18"/>
								<path d="m6 6 12 12"/>
							</svg>
						</btn>
					</div>
				</div>

				<expand-transition>
					<div v-show="showNavItems" class="overflow-hidden grow md:block h-screen md:h-auto">
						<div
							class="overflow-hidden overflow-y-auto max-h-screen pb-16 md:pb-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
							<div
								class="flex flex-col divide-y divide-dashed divide-gray-200 md:flex-row md:items-center md:justify-end py-2 md:py-4 md:ps-7 md:divide-y-0 md:divide-solid dark:divide-neutral-700">

								<about-app-header-nav-item v-for="(item, itemKey) in items" :key="itemKey" :item="item" :active="item.to && route.path.startsWith(item.to)" @click-item="handleNavItemClick"/>

								<div class="h-full flex items-center justify-between w-full md:w-auto pt-4 md:pt-0 lg:ml-4">
									<btn :to="route.path.startsWith('/resultastic') ? $config.public.resultasticUrl : $config.public.spoferanUrl"
										 :icon="icons.next"
										 icon-right
										 rounded-full small
										 :label="t('action.get_started_now')"
										 class="md:max-lg:hidden"
										 color="primary"/>

									<div class="ml-5 pl-2 border-l dark:border-gray-700">
										<dark-mode-btn/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</expand-transition>
			</div>
		</container>
	</header>
</template>
<script setup lang="ts">
import {disableWindowScroll, enableWindowScroll, Images} from "@spoferan/spoferan-ts-core";
import {mdiBookOpenBlankVariantOutline, mdiCalendarMonthOutline, mdiCubeOutline, mdiEarth, mdiHandshakeOutline, mdiPercentOutline, mdiPostOutline} from "@mdi/js";
import {downIcon, nextIcon} from "@spoferan/nuxt-spoferan/icons";

const props = withDefaults(defineProps<{
	// Whether the navbar shall receive a bottom border
	border?: boolean,

	// Whether the navbar container shall be full width
	fluid?: boolean,

	// Whether no shadow shall be added
	flat?: boolean,
}>(), {
	border: false,
	fluid: false,
	flat: false
});

const {$device, $web} = useNuxtApp();
const {t} = useI18n();
const route = useRoute();

const showNavItems = ref($device.isDesktop);
watch(() => $device.isDesktop, (newValue) => {
	showNavItems.value = newValue;
});

const items = [
	{
		label: t('label.athletes'), extended_label: t('view.card_athletes.title'), app_name: 'SPOFERAN', to: $web('spoferan.index'), logo: Images.spoferan.logo, items: [
			{label: t('label.overview'), description: t('view.nav.athletes.overview'), to: $web('spoferan.index'), icon: mdiBookOpenBlankVariantOutline, exact: true},
			{label: t('label.competition_calendar'), description: t('view.nav.athletes.competition_calendar'), to: $web('spoferan.events'), icon: mdiCalendarMonthOutline},
			{label: t('label.sport_blog'), description: t('view.nav.athletes.blog'), to: $web('spoferan.blog'), icon: mdiPostOutline},
		]
	},
	{
		label: t('label.organizers'), extended_label: t('view.card_organizers.title'), app_name: 'resultastic', to: $web('resultastic.index'), logo: Images.resultastic.logo, logo_white: Images.resultastic.logo_white, items: [
			{label: t('label.overview'), description: t('view.nav.organizers.overview'), to: $web('resultastic.index'), icon: mdiBookOpenBlankVariantOutline, exact: true},
			{label: t('label.features'), description: t('view.nav.organizers.features'), to: $web('resultastic.features.index'), icon: mdiCubeOutline},
			{label: t('label.pricing'), description: t('view.nav.organizers.pricing'), to: $web('resultastic.pricing'), icon: mdiPercentOutline},
			{label: t('label.services'), description: t('view.nav.organizers.services'), to: $web('resultastic.services'), icon: mdiHandshakeOutline},
		]
	},
	{
		label: t('label.clubs'), extended_label: t('view.card_clubs.title'), app_name: 'SPOFERAN Clubmanager', to: $web('clubmanager.index'), logo: Images.clubmanager.logo, items: [
			{label: t('label.overview'), description: t('view.nav.clubs.overview'), to: $web('clubmanager.index'), icon: mdiBookOpenBlankVariantOutline, exact: true},
			{label: t('label.features'), description: t('view.nav.clubs.features'), to: $web('clubmanager.features.index'), icon: mdiCubeOutline},
			{label: t('label.pricing'), description: t('view.nav.clubs.pricing'), to: $web('clubmanager.pricing'), icon: mdiPercentOutline},
		]
	},
	{
		label: t('label.companies'), extended_label: t('view.card_companies.title'), app_name: 'SPOFERAN Businessmanager', to: $web('businessmanager.index'), logo: Images.businessmanager.logo, items: [
			{label: t('label.overview'), description: t('view.nav.companies.overview'), to: $web('businessmanager.index'), icon: mdiBookOpenBlankVariantOutline, exact: true},
			{label: t('label.features'), description: t('view.nav.companies.features'), to: $web('businessmanager.features.index'), icon: mdiCubeOutline},
			{label: t('label.pricing'), description: t('view.nav.companies.pricing'), to: $web('businessmanager.pricing'), icon: mdiPercentOutline},
		]
	},
];

const logos = [
	{
		value: 'resultastic',
		name: 'resultastic',
		src: Images.resultastic.logo.path,
		ratio: Images.resultastic.logo.ratio,
		height: 30,
		white: {
			src: Images.resultastic.logo_white.path,
			ratio: Images.resultastic.logo_white.ratio,
			height: 40
		}
	},
	{
		value: 'clubmanager',
		name: 'SPOFERAN Clubmanager',
		src: Images.clubmanager.logo.path,
		ratio: Images.clubmanager.logo.ratio,
		height: 32
	},
	{
		value: 'businessmanager',
		name: 'SPOFERAN Businessmanager',
		src: Images.businessmanager.logo.path,
		ratio: Images.businessmanager.logo.ratio,
		height: 32
	},
	{
		value: 'spoferan',
		name: 'SPOFERAN',
		src: Images.logo.path,
		ratio: Images.logo.ratio,
		height: 26
	}
]

const visibleLogoValue = computed(() => {
	if (route.path.startsWith('/resultastic')) {
		return 'resultastic'
	}
	if (route.path.startsWith('/clubmanager')) {
		return 'clubmanager'
	}
	if (route.path.startsWith('/businessmanager')) {
		return 'businessmanager'
	}

	return 'spoferan'
})

const icons = {
	down: downIcon,
	next: nextIcon
}

watch(showNavItems, (newValue) => {
	if (newValue && !$device.isDesktop) {
		disableWindowScroll();
	} else {
		enableWindowScroll();
	}
});

function handleNavItemClick() {
	if (!$device.isDesktop) {
		showNavItems.value = false;
	}
}


</script>