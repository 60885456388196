<template>
	<div>
		<template v-if="props.item.items?.length">
			<pre-dropdown placement="bottom-right" class="hidden md:block py-2 md:py-0 lg:px-1">
				<btn :label="props.item.label" :icon="icons.down" :color="props.active || isToggled ? 'primary' : undefined" icon-right text/>

				<template #menu>
					<div class="md:w-80">
						<div v-for="(subItem, subItemKey) in item.items" :key="subItemKey" class="py-2 border-b dark:border-neutral-700">
							<nuxt-link :to="subItem.to"
									   @click="handleNavItemClick(subItem)"
									   class="flex items-start gap-x-5 w-full p-4 text-gray-600 rounded-lg hover:bg-gray-100 focus:ring-2 focus:ring-primary dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300">
								<icon :icon="subItem.icon" class="flex-shrink-0" small/>
								<div class="grow">
									<span class="block text-sm font-semibold mb-1 text-gray-800 dark:text-neutral-200">{{ subItem.label }}</span>
									<span class="text-sm">{{ subItem.description }}</span>
								</div>
							</nuxt-link>
						</div>

						<nuxt-link class="flex items-center justify-center pt-6 pb-4" :to="item.to" @click="handleNavItemClick(item)">
							<cdn-image :src="item.logo.path" :width="164" :ratio="item.logo.ratio"
									   :class="{'flex dark:hidden': !!item.logo_white}"
									   :alt="item.app_name"/>
							<cdn-image v-if="item.logo_white" :src="item.logo_white.path" :width="164" :ratio="item.logo_white.ratio"
									   class="hidden dark:flex"
									   :alt="item.app_name"/>
						</nuxt-link>
					</div>
				</template>
			</pre-dropdown>

			<div class="block md:hidden py-1">
				<button @click="isToggled = !isToggled" class="flex items-center justify-between w-full px-3 py-4 rounded-xl" :class="props.active || isToggled ? 'bg-gray-50 dark:bg-neutral-700' : ''">
					<div class="flex flex-col items-start">
						<span class="text-sm text-primary dark:text-neutral-400 ">{{ item.app_name }}</span>
						<span class="text-base font-medium text-gray-800 dark:text-neutral-200">{{ item.extended_label }}</span>
					</div>
					<icon :icon="isToggled ? icons.down : icons.right" class="size-5 text-gray-600 dark:text-neutral-400"/>
				</button>

				<expand-transition>
					<div v-show="isToggled" :aria-expanded="isToggled" class="px-1">
						<div v-for="(subItem, subItemKey) in item.items" :key="subItemKey" class="py-2 border-b dark:border-neutral-700">
							<nuxt-link :to="subItem.to"
									   @click="handleNavItemClick(subItem)"
									   class="flex items-start gap-x-5 w-full p-4 text-gray-600 rounded-lg hover:bg-gray-100 focus:ring-2 focus:ring-primary dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300">
								<icon :icon="subItem.icon" class="flex-shrink-0" small/>
								<div class="grow">
									<span class="block text-sm font-semibold mb-1 text-gray-800 dark:text-neutral-200">{{ subItem.label }}</span>
									<span class="text-sm">{{ subItem.description }}</span>
								</div>
							</nuxt-link>
						</div>

						<nuxt-link class="flex items-center justify-center pt-6 pb-4" :to="item.to" @click="handleNavItemClick(item)">
							<cdn-image :src="item.logo.path" :width="164" :ratio="item.logo.ratio"
									   :class="{'flex dark:hidden': !!item.logo_white}"
									   :alt="item.app_name"/>
							<cdn-image v-if="item.logo_white" :src="item.logo_white.path" :width="164" :ratio="item.logo_white.ratio"
									   class="hidden dark:flex"
									   :alt="item.app_name"/>
						</nuxt-link>
					</div>
				</expand-transition>
			</div>

		</template>
		<nuxt-link v-else class="font-medium text-gray-800 hover:text-gray-600 py-3 md:px-3 md:py-6 dark:text-neutral-200 dark:hover:text-neutral-500"
				   @click="handleNavItemClick(item)"
				   :to="item.to">
			{{ item.label }}
		</nuxt-link>
	</div>
</template>

<script setup lang="ts">
import {downIcon, nextIcon} from "@spoferan/nuxt-spoferan/icons";

type SubNavItem = {label: string, icon: string, description: string, to?: object|string};
type NavItem = { label: string, extended_label: string, app_name: string, to?: object|string, logo: {path: string, ratio: number}, logo_white?: {path: string, ratio: number}, items?: SubNavItem[] };

const props = defineProps<{
	item: NavItem,
	active?: boolean,
}>();
const emit = defineEmits(['click-item']);

const icons = {
	down: downIcon,
	right: nextIcon,
}

const isToggled = ref(props.active);
watch(() => props.active, (newValue) => {
	isToggled.value = !!newValue;
});

function handleNavItemClick(item: NavItem|SubNavItem) {
	emit('click-item', item);
}
</script>